/**
 * @description 获取导航标签列表信息
 */
export function getTabs() {
  return [
    { tab:'inicio', icon: '/icons/tabbar/inicio.png', selectionIcon: '/icons/tabbar/inicio_selected.png', text: 'main.inicio', route: '/main/inicio' },
    { tab:'promo', icon: '/icons/tabbar/promo.png', selectionIcon: '/icons/tabbar/promo_selected.png', text: 'main.promo', route: '/main/promo' },
    { tab:'entrar', icon: '/icons/tabbar/entrar.png', selectionIcon: '/icons/tabbar/entrar_selected.png', text: 'main.entrar', route: '/main/entrar' },
    { tab:'suporte', icon: '/icons/tabbar/suporte.png', selectionIcon: '/icons/tabbar/suporte_selected.png', text: 'main.suporte', route: '/main/suporte' },
    { tab:'perfil', icon: '/icons/tabbar/perfil.png', selectionIcon: '/icons/tabbar/perfil_selected.png', text: 'main.perfil', route: '/main/perfil' }
  ]
}