import { createI18n } from 'vue-i18n';
import en_US from './locales/en_US.json';
import zh_CN from './locales/zh_CN.json';

const i18n = createI18n({
  legacy: false,    // 设置为 false 来启用 composition API 模式
  locale: 'en_US',  // 默认显示的语言 
  messages: {
    en_US,
    zh_CN,
  }
})

export default i18n;