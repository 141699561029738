<script setup lang="ts">
import { onBeforeMount } from 'vue'
import { useAppStore } from './store/app'
import { useSystemStore } from './store/system'
import { IonApp, IonRouterOutlet, IonContent } from '@ionic/vue'

const appStore = useAppStore();       // 用户信息
const systemStore = useSystemStore(); // 系统信息

/**
 * 生命周期-页面加载前
 */
onBeforeMount(async () => {
	await appStore.loadToken(); 				// 设置token
	await appStore.setLocale(); 				// 设置语言
	await systemStore.setAppInfo(); 		// 设置app信息
	await systemStore.setDeviceInfo();	// 设置系统信息
})

let width = window.innerWidth; 				// 屏幕宽度

// 设置基础字体大小
document.documentElement.style.fontSize = width > 450 ? `${450 * 16 / 390}px` : `${width * 16 / 390}px`
// 监听窗口大小变化
window.addEventListener('resize', () => {
	if (width > 450)
		width = 450
	document.documentElement.style.fontSize = `${width * 16 / 390}px`
})
</script>

<template>
	<IonApp>
		<IonContent id="app-content">
			<IonRouterOutlet class="max-w-md mx-auto"/>
		</IonContent>
	</IonApp>
</template>

<style>
@import 'swiper/css';                     /* 轮播图样式 */
@import 'swiper/css/pagination';          /* 轮播图指示器样式 */
@import 'tailwindcss/base';               /* tailwindcss样式库 */
@import 'tailwindcss/components';         /* tailwindcss样式库 */
@import 'tailwindcss/utilities';          /* tailwindcss样式库 */
@import '@/common/global.css';            /* 全局样式 */

#app-content {
  --background: url(/icons/texture_bg.png);
  background-size: 6rem;
}
</style>
