import { defineStore } from 'pinia'
import { Storage } from '@ionic/storage'
import { getKeyByValue } from '@/enums/library'
import { LanguageEnum } from '@/enums/language'
import i18n from '@/i18n'

const storage = new Storage();
(async () => {
	await storage.create()
})()

export const useAppStore = defineStore({
  id: 'app',
  state: () => ({
    token: '',							// 用户token
    locale: '',							// 语言
    account: '',						// 用户账号
    password: '',						// 用户密码
		thirdUrl: '',						// 第三方链接地址
		isThird: false,					// 是否跳转第三方链接
		oldVerifyToken: '',			// 旧的验证码token
		newVerifyToken: '',			// 新的验证码token
		operation: 'login',			// 操作类型(用于全局弹窗操作)
		modalVisible: false,		// 全局弹窗显示状态
    rememberAccount: false,	// 是否记住账号
  }),

	actions: {
		// 加载token
		async loadToken() {
			if (!this.token)
				this.token = await storage.get('token') || ''
			return this.token
		},
		// 设置token
		async setToken(token: string = '') {
			if (token) {
				await storage.set('token', token)
				this.token = token
			}
			else {
				await storage.remove('token')
				this.token = ''
			}
		},
		// 移除token
		async removeToken() {
			await storage.remove('token')
			this.token = ''
		},
		// 设置语言
		async setLocale(language: string = '') {
			if (language) {
				this.locale = getKeyByValue(language, LanguageEnum) ?? ''
				storage.set('locale', this.locale)
			}
			else {
				this.locale = await storage.get('locale') || ''
			}
			if (this.locale)
				i18n.global.locale.value = this.locale as "en_US" | "zh_CN"
		},
		// 获取语言
		async getLocale() {
			if (!this.locale)
				this.locale = await storage.get('locale') || ''
			return this.locale
		},
		// 保存用户账号
		setAccount(account: string, password: string) {
			storage.set('account', account)
			storage.set('password', password)
			this.account = account
			this.password = password
		},
		// 获取用户账号
		async getAccount() {
			if (!this.account)
				this.account = await storage.get('account') || ''

			return this.account
		},
		// 获取用户密码
		async getPassword() {
			if (!this.password)
				this.password = await storage.get('password') || ''

			return this.password
		},
		// 删除用户账号
		removeAccount() {
      this.account = this.password = '';
      storage.remove('account');
      storage.remove('password');
		},
		// 设置记住密码
		setRememberAccount(remember: boolean) {
			this.rememberAccount = remember
		},
		// 设置推荐人
		setParentId(parentId: string) {
			storage.set('parentId', parentId)
		},
		// 获取推荐人
		async getParentId() {
			return await storage.get('parentId') || ''
		},
		// 设置旧的验证码token
		setOldVerifyToken(token: string) {
			this.oldVerifyToken = token
			storage.set('oldVerifyToken', token)
		},
		// 获取旧的验证码token
		async getOldVerifyToken() {
			if (!this.oldVerifyToken)
				this.oldVerifyToken = await storage.get('oldVerifyToken') || ''
			return this.oldVerifyToken
		},
		// 设置新的验证码token
		setNewVerifyToken(token: string) {
			this.newVerifyToken = token
			storage.set('newVerifyToken', token)
		},
		// 获取新的验证码token
		async getNewVerifyToken() {
			if (!this.newVerifyToken)
				this.newVerifyToken = await storage.get('newVerifyToken') || ''
			return this.newVerifyToken
		}
	},
})
