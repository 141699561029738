import { defineStore } from 'pinia'
import { App, AppInfo } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { storeDefaultValue } from '@/hooks/StoreDefaultValue';

export const useSystemStore = defineStore({
  id: 'system',
  state: () => ({
    deviceId: '',                       // 设备id
    deviceModel: '',                    // 设备信息
    platform: Capacitor.getPlatform(),  // 平台
    app: storeDefaultValue<AppInfo>(),  // app信息
  }),

  actions: {
    // 设置app信息
    async setAppInfo() {
      if (this.platform === 'web') return;
      this.app = await App.getInfo();
    },
    // 设置设备信息
    async setDeviceInfo() {
      this.deviceId = (await Device.getId()).identifier;
      const info = await Device.getInfo();
      if (info.platform === 'web') {
        this.deviceModel = `${info.platform} ${info.model} ${info.operatingSystem} ${info.osVersion}`;
      } else {
        this.deviceModel = `${info.model} ${info.operatingSystem} ${info.osVersion}`;
      };
    },
  },
})