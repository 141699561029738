import { createRouter, createWebHashHistory } from '@ionic/vue-router';
import routes from './routes';
import i18n from '@/i18n';
import { modalController } from '@ionic/vue';
import { useAppStore } from '@/store/app';
import { delay } from '@/utils/delay';

const { t } = i18n.global;

let token: string

// 创建路由实例
const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes
})

// 全局前置守卫
router.beforeEach(async (to, from, next) => {
  token = await useAppStore().loadToken();
  if (to.meta.auth && !token && from.path == '/') {   // 需要登录验证 且 未登录 并 为初始页 则跳转到首页
    router.push({ path: '/main/inicio' })
    await delay(1000);
    return next(false);
  } else if (!to.meta.auth || token) {                // 不需要登录验证 或 已经登录 则放行
    return next()
  }
  if (useAppStore().modalVisible) return next(false); // 如果已经弹出了登录框，则不再弹出
  useAppStore().modalVisible = true;
  const { default: LoginComponent } = await import('@/components/AccountOperation/index.vue');
  useAppStore().operation = 'login';
  const modal = await modalController.create({
    component: LoginComponent
  });
  modal.present();

  next(false);  // 中断当前导航
})

// 全局后置守卫
router.afterEach((to, _from) => {
  if (to.meta.title) document.title = String(to.meta.title);  // 设置标题
})

export default router
