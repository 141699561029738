<script setup lang="ts">
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonPage, IonRouterOutlet, IonImg } from '@ionic/vue';
import { useRouter } from 'vue-router';
import { getTabs } from './data';
import { ref } from 'vue';

const router = useRouter();

const tabs = ref(getTabs()) // 获取底部导航栏数据

function navigato(route: string) {
	if (route == '/main/suporte') return
	router.push(route)
}
</script>

<template>
	<IonPage>
		<IonTabs>
			<IonRouterOutlet :animated="false"/>
			<IonTabBar slot="bottom">
				<IonTabButton v-for="item in tabs" :key="item.route" :tab="item.tab" @click="navigato(item.route)">
					<IonImg class="tab-img" :src="$route.path == item.route ? item.selectionIcon : item.icon" />
					<IonLabel class="text-[.625rem]" :style="$route.path == item.route ? 'color:#FFF' : ''">
						{{ $t(item.text) }}
					</IonLabel>
				</IonTabButton>
			</IonTabBar>
		</IonTabs>
	</IonPage>
</template>

<style>
ion-tab-button {
  --color-selected: #FFFFFF;  /* 底部导航栏选中字体颜色 */
  --color: #566488;           /* 底部导航栏默认字体颜色 */
}

.tab-img {
  width: 28px;  /* 底部导航栏图标宽度 */
}
</style>
