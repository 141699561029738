import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/tabbar/MainPage.vue';

/**
 * @description 路由列表
 * @property meta[auth] 是否需要登陆验证
 */
const routes: Array<RouteRecordRaw> = [
  { // 根路由
    path: '/',
    redirect: '/launch'
  },
  { // 启动页
    path: '/launch',
    component: () => import('@/views/system/launch/index.vue'),
  },
  { // 路由不存在时自动重定向
    path: '/:pathMatch(.*)*', 
    redirect: '/launch'
  },
  { // 主页
    path: '/main',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/main/inicio'
      },
      { // 首页
        path: 'inicio',
        component: () => import('@/views/tabbar/tabs/inicio/index.vue')
      },
      { // 优惠活动
        path: 'promo',
        component: () => import('@/views/tabbar/tabs/promo/index.vue')
      },
      { // 充值
        path: 'entrar',
        component: () => import('@/views/tabbar/tabs/entrar/index.vue'),
        meta: { auth: true }
      },
      { // 客服
        path: 'suporte',
        component: () => import('@/views/tabbar/tabs/suporte/index.vue')
      },
      { // 个人中心
        path: 'perfil',
        component: () => import('@/views/tabbar/tabs/perfil/index.vue'),
        meta: { auth: true },
      }
    ]
  },
  { // 游戏分类
    path: '/game/category/:gameType',
    name: 'GameCategory',
    component: () => import('@/views/game/category/index.vue')
  },
  { // 游戏搜索
    path: '/game/search',
    name: 'GameSearch',
    component: () => import('@/views/game/search/index.vue')
  },
  { // 游戏大厅
    path: '/game/action/:id',
    name: 'GameAction',
    component: () => import('@/views/game/action/index.vue'),
    meta: { auth: true }
  },
  { // 登陆
    path: '/login',
    component: () => import('@/views/system/login/index.vue'),
  },
  { // 注册
    path: '/register',
    component: () => import('@/views/system/register/index.vue'),
  },
  { // 忘记密码
    path: '/forget',
    component: () => import('@/views/system/forget/index.vue'),
  },
  { // 重置密码
    path: '/reset',
    component: () => import('@/views/system/reset/index.vue'),
  },
  { // 充值操作
    path: '/recharge/apply',
    component: () => import('@/views/assets/recharge/apply/index.vue'),
    meta: { auth: true }
  },
  { // 充值记录
    path: '/recharge/record',
    component: () => import('@/views/assets/recharge/record/index.vue'),
  },
  { // 提现密码
    path: '/withdraw/password',
    component: () => import('@/views/assets/withdraw/password/index.vue'),
    meta: { auth: true }
  },
  { // 提现密码
    path: '/withdraw/record',
    component: () => import('@/views/assets/withdraw/record/index.vue'),
    meta: { auth: true }
  },
  { // 提现操作
    path: '/withdraw/apply',
    component: () => import('@/views/assets/withdraw/apply/index.vue'),
    meta: { auth: true }
  },
  { // 提现方式列表
    path: '/withdraw/method/list',
    component: () => import('@/views/assets/withdraw/method/list/index.vue'),
    meta: { auth: true }
  },
  { // 提现方式添加或编辑
    path: '/withdraw/method/edit/:id',
    component: () => import('@/views/assets/withdraw/method/edit/index.vue'),
    meta: { auth: true }
  },
  { // 添加实名
    path: '/withdraw/realName',
    component: () => import('@/views/assets/withdraw/realName/index.vue'),
    meta: { auth: true }
  },
  { // 推广中心
    path: '/spread',
    component: () => import('@/views/spread/main/index.vue'),
  },
  { // 安全中心
    path: '/security',
    component: () => import('@/views/security/main/index.vue')
  },
  { // 安全验证
    path: '/security/verify/:type',
    component: () => import('@/views/security/verify/index.vue')
  },
  { // 安全验证
    path: '/security/confirm/:type',
    component: () => import('@/views/security/confirm/index.vue')
  },
  { // 通知中心
    path: '/notification',
    component: () => import('@/views/user/notification/main/index.vue')
  },
  { // 转盘活动
    path: '/activity/wheel',
    component: () => import('@/views/activity/wheel.vue')
  },
  { // VIP活动
    path: '/activity/vip',
    component: () => import('@/views/activity/vip.vue')
  },
  { // 签到活动
    path: '/activity/sign',
    component: () => import('@/views/activity/sign.vue')
  },
  { // 矿山活动
    path: '/activity/mineral',
    component: () => import('@/views/activity/mineral.vue')
  },
  { // 救援金活动
    path: '/activity/rescue',
    component: () => import('@/views/activity/rescue.vue')
  }
]

export default routes;